@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}

.loading-animation {
  animation: pulse 1.2s infinite;
}
